import { Platform } from "react-native";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { ModalVariant } from "../types";

const isHandledByNavigation = (variant: ModalVariant) => {
	if (Platform.OS === "web") {
		return false;
	}
	switch (variant) {
		case "Short":
		case "Tall":
			return false;
		case "Full":
		case "Blur":
		case "ShortBlur":
		case "FullBlur":
		case "FullFull":
		case "FullEmpty":
			return true;
		default:
			return enumNever(variant);
	}
};
export default isHandledByNavigation;
