import "./css/SafeModal.css";

import { ComponentProps, useEffect, useMemo, useRef, useState } from "react";
import type { createPortal as createPortalType } from "react-dom";
import type { Modal as NativeModal } from "react-native";
import log from "src/helpers/log";
import { FixedPosition } from "src/styles/position";
import { MDiv } from "src/swsh-native";

const ANIMATION_DURATION = 200;

const SafeModalContent = ({
	children,
	createPortal,
	visible,
	testID,
}: {
	createPortal: typeof createPortalType;
	visible: boolean;
} & ComponentProps<typeof NativeModal>) => {
	const elementRef = useRef<HTMLDivElement | null>(null);

	// Using useMemo here to ensure that elementRef is defined at first render
	useMemo(() => {
		// Aiming to have only the active modals in the DOM at any given time
		if (typeof window !== "undefined" && !elementRef.current) {
			const element = document.createElement("div");

			if (element && document.body) {
				document.body.appendChild(element);
				elementRef.current = element;
			}
		}
	}, []);

	useEffect(() => {
		return () => {
			if (document.body && elementRef.current) {
				try {
					if (document.body.contains(elementRef.current)) {
						document.body.removeChild(elementRef.current);
					}
				} catch (err) {
					log.info({ err }, "Error in SafeModal useEffect cleanup");
				}
				elementRef.current = null;
			}
		};
	}, []);

	if (!elementRef.current) {
		log.error("Missing expected elementRef");
		return null;
	}
	return createPortal(
		<MDiv
			data-testid={testID}
			className="safe-modal"
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: visible ? 1 : 0,
			}}
			transition={{
				duration: ANIMATION_DURATION / 1000,
			}}
			pointerEvents={visible ? "box-none" : "none"}
			style={{
				position: FixedPosition,
				top: 0,
				left: 0,
				overflow: "hidden",
			}}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			{children}
		</MDiv>,
		elementRef.current,
	);
};

// Thank you Fernando https://github.com/nandorojo/zeego/issues/74#issuecomment-1930548711
const SafeModal = ({ children, visible = false, testID }: ComponentProps<typeof NativeModal>) => {
	const [createPortal, setCreatePortal] = useState<typeof createPortalType | null>(null);

	const [lingerVisible, setLingerVisible] = useState(visible);
	const timeout = useRef<NodeJS.Timeout | null>(null);
	useEffect(() => {
		import("react-dom").then((module) => {
			setCreatePortal(() => module.createPortal);
		});
	}, []);

	const visibleRef = useRef(visible);
	useEffect(() => {
		visibleRef.current = visible;
		if (visible) {
			setLingerVisible(true);
		} else {
			if (timeout.current) clearTimeout(timeout.current);
			timeout.current = setTimeout(() => {
				if (!visibleRef.current) {
					setLingerVisible(false);
				}
			}, 200);
		}
		return () => {
			if (timeout.current) clearTimeout(timeout.current);
		};
	}, [visible]);

	if (!lingerVisible) return null;
	if (!createPortal) return null;
	return (
		<SafeModalContent createPortal={createPortal} visible={visible} testID={testID}>
			{children}
		</SafeModalContent>
	);
};

export default SafeModal;
