import { useState } from "react";
import useMountEffect from "src/hooks/useMountEffect";
import { IndividualModalConfig } from "../types";
import useModalState from "./useModalState";

/**
 * Registers a modal configuration with the modal state.
 *
 * `name` and `config` must never change.
 */
const useModalRegistration = (name: string, config: IndividualModalConfig<any, any>) => {
	// UNSAFE: You almost never want to set store values within the initialization of a hook
	// Using useState lazy init to ensure this only runs once
	const [alreadyRegistered] = useState(() => {
		const { registerModalConfig } = useModalState.getState();
		const { alreadyRegistered } = registerModalConfig(name, config);
		return alreadyRegistered;
	});
	useMountEffect(() => {
		if (alreadyRegistered) return;
		const { unregisterModalConfig } = useModalState.getState();
		return () => {
			unregisterModalConfig(name);
		};
	}, []);
};
export default useModalRegistration;
