import "./css/RootModalIndividual.css";

import { memo, useMemo } from "react";
import SafeModal from "src/components/SafeModal";
import isWeb from "src/constants/isWeb";
import useDevice from "src/hooks/useDevice";
import useStyle from "src/hooks/useStyle";
import ModalOpenDataManager from "src/modals/containers/components/ModalOpenDataManager/ModalOpenDataManager";
import { innerBlurBackgroundColor, outerBlurStyle } from "src/modals/containers/styles";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { color } from "src/styles";
import { radiusMedium } from "src/styles/radius";
import { MDiv } from "src/swsh-native";
import { MDivProps } from "src/swsh-native/MDiv/types";
import { ModalContext, ModalContextType } from "../../../../contexts/ModalContext";
import useModalRegistration from "../../../../hooks/useModalRegistration";
import useModalState from "../../../../hooks/useModalState";
import { RootModalIndividualProps } from "./types";

const width90 = (isWeb ? "90vw" : "90%") as "90%";
const height90 = (isWeb ? "90vh" : "90%") as "90%";
const RootModalIndividual = ({ name, config }: RootModalIndividualProps) => {
	useModalRegistration(name, config);
	const variant = config.variant;

	const isOpen = useModalState((state) => state.openLookup[name]?.isOpen ?? false);
	const { theme } = useStyle();
	const { device } = useDevice();
	const close = useModalState((state) => state.close);

	const viewProps = useMemo<Omit<MDivProps, "className" | "pointerEvents">>(() => {
		switch (variant) {
			case "Short":
				return {
					style: {
						justifyContent: "center",
						alignItems: "center",
					},
				};
			case "Tall":
				if (device === "mobile") {
					return {};
				}
				return {
					style: {
						justifyContent: "center",
						alignItems: "center",
					},
				};
			case "Full":
				if (device === "mobile") {
					return {};
				}
				return {
					style: {
						justifyContent: "center",
						alignItems: "center",
					},
				};
			case "Blur":
				return {
					style: outerBlurStyle,
				};
			case "ShortBlur":
				return {
					style: [
						{
							justifyContent: "center",
							alignItems: "center",
						},
						outerBlurStyle,
					],
				};
			case "FullBlur":
				return {
					style: [
						outerBlurStyle,
						device === "mobile"
							? undefined
							: {
									alignItems: "center",
									justifyContent: "center",
								},
					],
				};
			case "FullFull":
				return {};
			case "FullEmpty":
				return {};
			default:
				return enumNever(variant);
		}
	}, [variant, device]);

	const nestedViewProps = useMemo<Omit<MDivProps, "className">>(() => {
		switch (variant) {
			case "Short":
				return {
					style: {
						backgroundColor: color.BackgroundModal[theme],
						overflow: "hidden",
						borderRadius: radiusMedium,
						maxWidth: width90,
					},
				};
			case "Tall":
				if (device === "mobile") {
					return {
						style: {
							backgroundColor: color.BackgroundModal[theme],
							flex: 1,
						},
					};
				}
				return {
					style: {
						backgroundColor: color.BackgroundModal[theme],
						borderRadius: radiusMedium,
						overflow: "hidden",
						minWidth: 350,
						maxHeight: height90,
					},
				};
			case "Full":
				if (device === "mobile") {
					return {
						style: {
							backgroundColor: color.BackgroundModal[theme],
							flex: 1,
						},
					};
				}
				return {
					style: {
						backgroundColor: color.BackgroundModal[theme],
						borderRadius: radiusMedium,
						overflow: "hidden",
						minWidth: 400,
						minHeight: 400,
						maxHeight: height90,
					},
				};
			case "ShortBlur":
				return {
					style: {
						backgroundColor: innerBlurBackgroundColor,
						overflow: "hidden",
						borderRadius: radiusMedium,
						maxWidth: width90,
					},
				};
			case "Blur":
				return {
					style: {
						flex: 0,
					},
				};
			case "FullBlur":
				if (device === "mobile") {
					return {
						style: {
							flex: 1,
						},
					};
				}
				return {
					style: {
						backgroundColor: innerBlurBackgroundColor,
						borderRadius: radiusMedium,
						overflow: "hidden",
						minWidth: 400,
						minHeight: 400,
						maxHeight: height90,
					},
				};
			case "FullFull":
				return {
					style: {
						backgroundColor: color.BackgroundModal[theme],
						flex: 1,
					},
				};
			case "FullEmpty":
				return {
					style: {
						flex: 1,
					},
				};
			default:
				return enumNever(variant);
		}
	}, [variant, theme, device]);

	const isFullScreen =
		(variant === "Tall" || variant === "Full" || variant === "FullBlur") && device === "mobile";
	const hasTouchable = (!isFullScreen || variant === "FullBlur") && (config.allowClose ?? true);
	const modalContextValue = useMemo<ModalContextType>(() => {
		return {
			name,
			closeSelf: () => close(name),
		};
	}, [close, name]);

	return (
		<SafeModal testID="Modal" visible={isOpen}>
			<ModalContext.Provider value={modalContextValue}>
				<ModalOpenDataManager name={name} config={config} />
				<MDiv {...viewProps} className="modal-container" pointerEvents="box-none">
					{hasTouchable && (
						<div
							className="modal-touchable"
							style={{
								backgroundColor: color.BackgroundOverlay[theme],
							}}
							onClick={() => {
								close(name);
							}}
						/>
					)}
					<MDiv {...nestedViewProps} className="modal-content">
						<config.component name={name} variant={variant} />
					</MDiv>
				</MDiv>
			</ModalContext.Provider>
		</SafeModal>
	);
};
export default memo(RootModalIndividual);
