import { useEffect } from "react";
import ErrorComponent from "src/components/ErrorComponent";
import Shimmer from "src/components/Shimmer";
import log from "src/helpers/log";
import { LazyImporter, LazyOptions, LazyResult } from "./types";

// Nathan's note:
// "next/dynamic" didn't properly contain a preload function. For some reason, it only exists when we import directly like this
import dynamic from "next/dist/shared/lib/dynamic";

const lazy = <P extends {}>(
	importer: LazyImporter<P>,
	{ loading, ...options }: LazyOptions<P> = {},
): LazyResult<P> => {
	let promise: ReturnType<typeof importer> | undefined = undefined;
	const cachedImporter: typeof importer = async () => {
		if (promise === undefined) {
			promise = importer().catch((err) => {
				return () => {
					useEffect(() => {
						log.error({ err }, "Error loading lazy component");
						// Ran into issues with refresh loops in subdomain testing. Disabling for now.
						/*
						if (isWeb && err instanceof Error && err.name === "ChunkLoadError") {
							const timeout = setTimeout(() => {
								window.location.reload();
							}, 500);
							return () => {
								clearTimeout(timeout);
							};
						}
                        */
						return;
					}, [err]);
					return (<ErrorComponent />) as any;
				};
			});
		}
		return promise;
	};

	const dynamicResult = dynamic(cachedImporter, {
		loading:
			loading ??
			(() => (
				<Shimmer
					colorVariant="Modal"
					style={{
						flex: 1,
						minWidth: 150,
						minHeight: 150,
					}}
				/>
			)),
		...options,
	});

	// We always want to preload as soon as possible
	// Otherwise, we increase the risk for a chunk mismatch
	// Chunk mismatches happen when we deploy a new version of the site and an older client lazy loads a chunk that no longer exists
	// See https://linear.app/somewhere-somehow/issue/ENG-2768/web-500-errors
	if (typeof window !== "undefined") {
		if ("render" in dynamicResult && "preload" in (dynamicResult.render as any)) {
			(dynamicResult.render as any).preload?.();
		}
	}

	return dynamicResult;
};
export default lazy;
